
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://classpass.com');
    }, 1);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="dhiwise-navigation">
      
      <p className="headline">
     
      </p>
    </div>
  );
};
export default Home;
